import React from "react";
import { graphql } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import Subfooter from "../components/subfooter";
import remark from "remark";
import recommended from "remark-preset-lint-recommended";
import remarkHtml from "remark-html";
import Block from "../components/block";
import HeroTeam from "../components/heroTeam";

function ContractingPage({ location, data }) {
  const content = data.contractingPage.frontmatter;

  return (
    <Layout location={location}>
      <SEO keywords={[`bloom`, `bloom works`]} title="Bloom Works" />
      <HeroTeam
        className={`bloom-pt-hero lg:bloom-pt-heroXL`}
        text={content.hero.heroText}
      />
      {content.blocks?.map((block, index) => {
        const contentHTML = remark()
          .use(recommended)
          .use(remarkHtml)
          .processSync(block.content)
          .toString();

        return (
          <Block className={`${block.theme || `bloom-bg-white`}`}>
            <div className={`bloom-mb-5 bloom-flex`}>
              <h2
                className={`bloom-text-4xl bloom-uppercase bloom-font-bold bloom-border-b-4 bloom-border-green`}
              >
                {block.title}
              </h2>
            </div>
            <div
              className={`bloom-prose`}
              dangerouslySetInnerHTML={{ __html: contentHTML }}
            />
          </Block>
        );
      })}
      <Subfooter></Subfooter>
    </Layout>
  );
}

export const pageQuery = graphql`
  query ContractingTemplate {
    contractingPage: markdownRemark(
      frontmatter: { templateKey: { eq: "contracting" } }
    ) {
      html
      frontmatter {
        title
        subtitle
        blocks {
          title
          content
          theme
        }
        hero {
          heroText
        }
      }
    }
  }
`;

export default ContractingPage;
